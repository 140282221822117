<template>
    <b-card>
        <b-form @submit.prevent>
            <app-collapse>
                <app-collapse-item title="Búsqueda"
                                   :is-visible="isVisible">
                    <b-row>
                        <b-col md="12">
                            <b-form-group

                            >
                                <h5 class="font-weight-bold">
                                    Cursos
                                </h5>
                                <div class="demo-inline-spacing curso">

                                    <b-form-radio
                                            v-model="filter.type"
                                            name="type"
                                            value="1"
                                            class="custom-control-primary"
                                    >
                                        Activos
                                    </b-form-radio>
                                    <b-form-radio
                                            v-model="filter.type"
                                            name="type"
                                            value="2"
                                            class="custom-control-primary"
                                    >
                                        No Activos
                                    </b-form-radio>
                                  <b-form-radio
                                      v-model="filter.type"
                                      name="type"
                                      value="3"
                                      class="custom-control-primary"
                                  >
                                    Todos
                                  </b-form-radio>
                                </div>

                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha desde
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaDesde"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group>
                                <h5 class="font-weight-bold">
                                    Fecha Hasta
                                </h5>
                                <div class="input-group">
                                    <flat-pickr
                                            v-model="filter.fechaHasta"
                                            class="form-control"
                                            placeholder="Click para seleccionar..."
                                            :config="config"
                                            reset-button
                                    />
                                    <div class="input-group-append">
                                        <button class="btn-clear" type="button" title="Limpiar" data-clear>
                                            <feather-icon
                                                    icon="XIcon"
                                            />
                                        </button>
                                    </div>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Ficha
                                </h5>
                                <b-form-input
                                        id="ficha"
                                        v-model="filter.ficha"
                                        placeholder="N° de Ficha"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Orden de Compra
                                </h5>
                                <b-form-input
                                        id="ordenCompra"
                                        v-model="filter.ordenCompra"
                                        placeholder="N° de Orden de Compra"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Holding
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="entidades/holding"
                                            placeholder="Seleccione Holding"
                                            @item-selected="selectHolding"
                                            label="nombre_holding"
                                            id="holding"
                                            itemsPerPage="15"
                                            :params="getFiltersHoldings()"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Clientes
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="entidades/empresa"
                                            placeholder="Seleccione Cliente"
                                            @item-selected="selectClientes"
                                            label="razon_social_empresa"
                                            id="empresa"
                                            itemsPerPage="15"
                                            :params="getFiltersEmpresas()"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="8">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Cursos
                                </h5>
                                <div class="form-label-group">
                                    <b-form-group>
                                        <autocomplete-infinity
                                                url="entidades/course-version-without-presencial-sychronous-modality"
                                                placeholder="Seleccione Curso"
                                                @item-selected="selectAplicaciones"
                                                label="description"
                                                itemsPerPage="15"
                                                id="curso"
                                                :params="getFiltersCourses()"
                                        ></autocomplete-infinity>

                                    </b-form-group>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group
                            >
                                <h5 class="font-weight-bold">
                                    Ejecutivos
                                </h5>
                                <div class="form-label-group">
                                    <autocomplete-infinity
                                            url="entidades/ejecutivo"
                                            placeholder="Seleccione Ejecutivos"
                                            @item-selected="selectEjecutivos"
                                            label="fullname"
                                            id="ejecutivo"
                                            itemsPerPage="15"
                                            :params="getFiltersEjecutivos()"
                                    ></autocomplete-infinity>
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <div class="text-right">
                                <b-button
                                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                        variant="outline-primary"
                                        type="submit"
                                        v-on:click="$emit('buscar', filter)">

                                    <feather-icon
                                            icon="SearchIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Buscar</span>
                                </b-button>
                                <b-button class="d-none"
                                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                          variant="outline-primary"
                                          type="submit">

                                    <feather-icon
                                            icon="RefreshCcwIcon"
                                            class="mr-50"
                                    />
                                    <span class="align-middle">Limpiar</span>
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </b-form>
    </b-card>
</template>


<script>
    import {
        BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormRadio, BFormDatepicker
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import flatPickr from 'vue-flatpickr-component'
    import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
    import vSelect from 'vue-select'

    export default {
        name: "FiltrosReportes",
        components: {
            AppCollapse,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BFormCheckbox,
            BForm,
            BButton,
            BFormRadio,
            flatPickr,
            AppCollapseItem,
            BFormDatepicker,
            vSelect,

        },
        directives: {
            Ripple,
        },
        props: ['reportType','isVisible'],
        data() {
            return {
                filter: {
                    fechaDesde: null,
                    fechaHasta: null,
                    type: 3,
                    ficha: null,
                    ordenCompra: null,
                    aplicacion: null,
                    holding: null,
                    ejecutivo: null,
                    cliente: null,
                },
            }
        },
        methods: {
           getFiltersEjecutivos(){
              return {
                "empresa" :this.filter.empresa,
                "holding" :this.filter.holding,
                "curso" :this.filter.aplicacion,
              }
            },
           getFiltersHoldings(){
            return {
              "empresa" :this.filter.empresa,
              "ejecutivo" :this.filter.ejecutivo,
              "curso" :this.filter.aplicacion,
            }
          },
          getFiltersCourses(){
            return {
              "empresa" :this.filter.empresa,
              "ejecutivo" :this.filter.ejecutivo,
              "holding" :this.filter.holding,
            }
          },getFiltersEmpresas(){
            return {
              "holding" :this.filter.holding,
              "ejecutivo" :this.filter.ejecutivo,
              "curso" :this.filter.aplicacion,
            }
          },
            selectHolding(holding) {
                this.filter.holding = holding != null ? holding.id_holding : null;
            },
            selectClientes(clientes) {
                this.filter.cliente = clientes != null ? clientes.id_empresa : null;
            },
            selectEjecutivos(ejecutivos) {
                this.filter.usuario = ejecutivos != null ? ejecutivos.id_usuario : null;
            },
            selectAplicaciones(aplicaciones) {
                this.filter.aplicacion = aplicaciones != null ? aplicaciones.id : null;
            },

        },
        computed: {
            config() {
                return {
                    locale: require(`flatpickr/dist/l10n/es.js`).default['es'],
                    dateFormat: "d-m-Y",
                    wrap: true,
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';

    .demo-inline-spacing.curso .custom-radio {
        margin-top: 0.5rem !important;
    }

    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>
